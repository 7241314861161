import { reactive } from 'vue';

class PayStatus {
  data = JSON.parse((localStorage.getItem('PayStatusData') as string) || '[]');
  timeLimitMinutes =
    JSON.parse(localStorage.getItem('timeLimitMinutes') as string) || 0;
  setTimeLimitMinutes(minutes: string) {
    localStorage.setItem('timeLimitMinutes', minutes);
    this.timeLimitMinutes = minutes || localStorage.getItem('timeLimitMinutes');
  }
  findDataPayStatus(id: string) {
    return this.data.find((item: any) => item.id == id);
  }
  setDataPayStatus(item: any) {
    const existingId = this.data.findIndex((it: any) => it.id === item.id);
    if (existingId !== -1) {
      this.data[existingId] = {
        payWay: item?.payWay,
        payWayName: item?.payWayName,
        payDesc: item?.payDesc,
        id: item?.id,
      };
    } else {
      this.data = [
        ...this.data,
        {
          payWay: item?.payWay,
          payWayName: item?.payWayName,
          payDesc: item?.payDesc,
          id: item?.id,
        },
      ];
    }
    localStorage.setItem('PayStatusData', JSON.stringify(this.data));
  }
}
export default reactive(new PayStatus());
