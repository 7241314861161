export const FRESH_TOKEN_URL = '/server/auth/token';

export const AUTO_LOGIN = '/v1/user/autoLogin';

export interface AutoLoginResp {
  id: number;
  name: string;
  avatar: string;
  token: string;
  email: string;
  level: number;
  cashBack: number;
  createTime: string;
  isFirstRecharge: number;
  pixelId?: string;
  exp: number;
  oldUserExpireTime?: string;
}

export const GET_CODE = '/server/auth/send/captcha';
export interface GetCodeReq {
  email: string;
}

export interface UserData {
  /*用户ID */
  userId: number;

  /*昵称 */
  nickname: string;

  /*头像 */
  avatar: string;

  /*邮箱 */
  email: string;

  /*团队等级 */
  teamLevel: number;

  /*分佣比例 */
  rate: number;

  /*达人卡名称 */
  talentCardName: string;

  /*达人卡类型<br>0：未开通、1：普通、2：高级 */
  talentCardType: number;

  /*达人卡到期日 */
  talentCardExpiredTime: Record<string, unknown>;

  /*最近一次购买的达人卡类型 */
  lastTalentCardType: number;

  /*钱包余额 */
  balance: string;

  /*星卡余额 */
  talentBalance: string;

  /*版权余额 */
  investmentBalance: string;

  /*直推拍剧额 */
  firstInviteMoney: string;

  /*版权累计收益 */
  investmentIncome: string;

  /*星卡分红返佣 */
  talentDividendAmount: string;

  /*版权分红返佣 */
  investmentDividendAmount: string;

  /*社区余额 */
  teamBalance: string;

  /*累计收益 */
  totalIncome: string;

  /*任务数 */
  taskNum: number;

  /*剩余兑换码个数 */
  codeNum: number;

  /*本月剩余兑换码个数 */
  monthCodeNum: number;

  /*推广人数 */
  marketUserNum: number;

  /*今日推广人数 */
  todayMarketUserNum: number;

  /*今日奖励 */
  todayReward: string;

  /*最小提现金额 */
  extractSingleMin: number;

  /*最大提现金额 */
  extractSingleMax: number;

  /*提现手续费 */
  extractChargeRate: string;

  /*每日提现次数 */
  extractDailyCount: number;

  /*转账手续费 */
  transferChargeRate: string;

  /*团队业绩 */
  teamMoney: string;

  /*有效直推人数 */
  effFirstInviteNum: string;

  /*有效直推金额 */
  effFirstInviteMoney: string;

  /*有效团队业绩 */
  effTeamMoney: string;

  /*直推人数 */
  directUserNum: number;
}

export const LOGIN_BY_EMAIL = '/server/auth/login/account';
export interface LoginByEmailReq {
  clientType: string;
  invitationCode?: string;
  email: string;
  mobile?: string;
  captcha?: string;
  password?: string;
}
export interface RegisterByEmailResp {
  newOrOld: 1 | 0;
  userInfo: UserData;
  tokenInfo: {
    access_token: string;
    refresh_token: string;
  };
}

export interface LoginByEmailResp {
  newOrOld: 1 | 0;
  userInfo: UserData;
  tokenInfo: {
    access_token: string;
    refresh_token: string;
  };
}
export const REGISTER_BY_EMAIL = '/server/auth/user/register';
export interface RegisterByEmailReq {
  clientType: string;
  email: string;
  invitationCode?: string;
  mobile?: string;
  captcha: string;
  password: string;
  secondPassword: string;
}

export interface InvestmentItem {
  id: number;
  name: string;
  /**当前收益率 */
  income: string;
  /**昨日收益率 */
  yesterdayIncome: string;
  userMinAmount: string;
  userMaxAmount: string;
  /**项目封面 */
  content: string;
  /**版权进度 */
  progress: string;
  /**进度金额（当前筹得） */
  progressMoney: string;
  /**虚拟浏览量 */
  virtualViews: number;
  totalMoney: string;
  /**	结束时间 */
  endTime: string;
  intervalMoneys: string[];
}
export const INVESTMENT_LIST = '/server/market/investment/page';
export interface InvestmentListReq {
  /**状态 1正在进行 2已结束 */
  status: number;
  page: number;
  limit: number;
  /**搜索内容 */
  search?: string;
  /**搜索类型 */
  searchType?: string;
  /**	排序字段 */
  order?: string;
  /**是否升序 */
  asc?: string;
}
export interface InvestmentListResp {
  total: number;
  list: InvestmentItem[];
  extend: any;
}

export const MARKET_USER = '/server/market/user-v2';

export interface MarketUserResp {
  /*用户ID */
  userId: number;

  /*昵称 */
  nickname: string;

  /*头像 */
  avatar: string;

  /*团队等级 */
  teamLevel: number;

  /*分佣比例 */
  rate: number;

  /*达人卡名称 */
  talentCardName: string;

  /*达人卡类型<br>0：未开通、1：普通、2：高级 */
  talentCardType: number;

  /*达人卡到期日 */
  talentCardExpiredTime: Record<string, unknown>;

  /*最近一次购买的达人卡类型 */
  lastTalentCardType: number;

  /*钱包余额 */
  balance: string;

  /*星卡余额 */
  talentBalance: string;

  /*版权余额 */
  investmentBalance: string;

  /*直推拍剧额 */
  firstInviteMoney: string;

  /*版权累计收益 */
  investmentIncome: string;

  /*星卡分红返佣 */
  talentDividendAmount: string;

  /*版权分红返佣 */
  investmentDividendAmount: string;

  /*社区余额 */
  teamBalance: string;

  /*累计收益 */
  totalIncome: string;

  /*任务数 */
  taskNum: number;

  /*剩余兑换码个数 */
  codeNum: number;

  /*本月剩余兑换码个数 */
  monthCodeNum: number;

  /*推广人数 */
  marketUserNum: number;

  /*今日推广人数 */
  todayMarketUserNum: number;

  /*今日奖励 */
  todayReward: string;

  /*最小提现金额 */
  extractSingleMin: number;

  /*最大提现金额 */
  extractSingleMax: number;

  /*提现手续费 */
  extractChargeRate: string;

  /*每日提现次数 */
  extractDailyCount: number;

  /*转账手续费 */
  transferChargeRate: string;

  /*团队业绩 */
  teamMoney: string;

  /*有效直推人数 */
  effFirstInviteNum: string;

  /*有效直推金额 */
  effFirstInviteMoney: string;

  /*有效团队业绩 */
  effTeamMoney: string;

  /*直推人数 */
  directUserNum: number;
}

export const LOGIN_OUT = '/server/auth/logout';

export const IM_AUTH = '/server/user/loginIm';

export const INVITE_BIND = '/server/invite/bind';

export interface InviteBindRep {
  invitationCode: string;
}

export const INVITE_INFO = '/server/invite/info';

export interface InviteInfoResp {
  /*直邀好友数量 */
  firstNum: number;

  /*二级好友数量 */
  secondNum: number;

  /*用户邀请码 */
  userInvitationCode: string;

  /*用户已绑定邀请码 */
  parentInvitationCode: string;

  /*用户邀请码二维码网址 */
  qrCode: string;

  /*短剧分享网址 */
  playletShareUrl: string;
}
export const WALLET_CHANGE = '/server/market/wallet/change/record';

export interface WalletChangeReq {
  /**当前页码 */
  page: string;
  /**	每页条数 */
  limit: string;
  /**	排序字段 */
  order?: string;
  /**是否升序 */
  asc?: string;
  /**状态 0：审核中 1：成功 2: 审核未通过 3: 已取消*/
  status?: number;
  /**搜索内容  */
  search?: string;
  /**搜索类型 */
  searchType?: string;
}

export interface WalletChangeResp {
  total: number;
  list: WalletChangeItem[];
  extend: any;
}

export interface WalletChangeItem {
  /**	单号   */
  recordNo: number;
  /**	类型 1：星卡余额 2：版权分红 3：社区收益 4：提现 */
  type: number;
  /**	金额 */
  amount: string;
  /**	状态 0：审核中 1：成功 2: 审核未通过 3: 已取消 */
  status: number;
  /**	类型字符串 */
  typeStr: string;
  /**	转账用户ID */
  transferUserId: string;
  /**	驳回原因 */
  reason: string;
  /**	时间 */
  time: string;
  /**	状态字符串 */
  statusStr: string;
}

export const WALLET_BALANCE = '/server/market/wallet/balance/extract/fields';

export interface WalletBalanceResp {
  key: string;
  name: string;
  required: number;
  extra: number;
  exp: string;
  message: string;
}

export const USDT_BALANCE = '/server/market/wallet/balance/extract/fields/usdt';

export interface USDTBalanceResp {
  key: string;
  name: string;
  required: number;
  extra: number;
  exp: string;
  message: string;
}

export const EXTRACT = '/server/market/wallet/balance/extract';

export interface ExtractReq {
  /**	提现金额 */
  amount: string;
  /**	开户行 */
  bank: string;
  /**		银行卡号 */
  bankCardNo?: string;
  /**	银行卡持有人 */
  bankCardHolder?: string;
  /**	支付宝账号 */
  alipayAccount?: string;
  /**	支付宝用户姓名 */
  alipayRealName?: string;
  /**	验证码 */
  captcha?: string;
  /**	支付密码 */
  payPassword?: string;
  /**	额外信息 */
  extra?: string;
  /**	1 三方支付 2 usdt支付 */
  extractType?: string;
}

export const USDT_INFO = '/server/market/usdt/info';

export const USDT_PAYINFO = '/server/market/usdt/pay/info';

export const USDT_TRANSFERINFO = '/server/market/usdt/transfer/info';

export const TIANXIE_CODE = '/server/market/wallet/balance/extract/captcha';

export const BANLANCE_TRANSFER = '/server/market/wallet/balance/transfer';

export interface BalanceTransferReq {
  /**	转账金额 */
  amount: string;
  /**	收款用户ID */
  userId: string;
  /**	验证码 */
  captcha?: string;
  /**	支付密码 */
  payPassword?: string;
}

export const INCOME_LIST = '/server/market/income/list';

export interface IncomeListReq {
  /**当前页码 */
  page: string;
  /**	每页条数 */
  limit: string;
  /**	排序字段 */
  order?: string;
  /**是否升序 */
  asc?: string;
  /**搜索内容  */
  search?: string;
  /**搜索类型 */
  searchType?: string;
  /**	是否为社区收益，可选参数 */
  team?: string;
}

export interface IncomeListResp {
  total: number;
  list: IncomeItem[];
  extend: Record<string, unknown>;
}

export interface IncomeItem {
  /*用户ID */
  userId: number;

  /*昵称 */
  nickname: string;

  /*头像 */
  avatar: string;

  /*类型<br>0：任务<br>1：会员<br>2：剧点<br>3：星卡<br>4：版权 */
  type: number;

  /*类型字符串 */
  typeStr: string;

  /*奖励金额 */
  amount: string;

  /*奖励时间 */
  time: Record<string, unknown>;
}

export const BANLANCE_IN = '/server/market/wallet/balance/in';

export interface BalanceInReq {
  /**	金额	 */
  amount?: string;
  /**	充值方式 1：星卡余额 2：版权分红 3：社区收益 */
  type?: number;
}

export const TALENT_CARD = '/server/market/talent/card/list';

export interface TalentCardResp {
  /*达人卡类型 */
  type: number;

  /*达人卡名称 */
  name: string;

  /*达人卡价格列表 */
  list: {
    /*ID */
    id: number;

    /*名称 */
    cardName: string;

    /*生效天数 */
    days: number;

    /*价格 */
    price: string;

    /*原本价格 */
    originalPrice: string;
  };
}

export const TALENT_CARD_EQUITY = '/server/market/talent/card/equity';

export interface TalentCardEquityResp {
  /*类型 */
  type: number;

  /*名称 */
  name: string;

  /*权益列表 */
  equityList: Record<string, unknown>[];
}

export const INVESTMENT_PAGE = '/server/market/investment/page';

export interface InvestmentPageResp {
  /*总记录数 */
  total: number;

  /*列表数据 */
  list: {
    /*ID */
    id: number;

    /*拍剧项目名 */
    name: string;

    /*当前收益率 */
    income: string;

    /*昨日收益率 */
    yesterdayIncome: string;

    /*单人最小认购金额 */
    userMinAmount: string;

    /*单人最大认购份额 */
    userMaxAmount: string;

    /*项目封面 */
    content: string;

    /*版权进度 */
    progress: string;

    /*进度金额（当前筹得） */
    progressMoney: string;

    /*虚拟浏览量 */
    virtualViews: number;

    /*最大认购金额 */
    totalMoney: string;

    /*结束时间 */
    endTime: string;

    /*拍剧金额列表 */
    intervalMoneys: Record<string, unknown>[];
  }[];

  /*扩展数据 */
  extend: Record<string, unknown>;
}

export interface InvestmentPageReq {
  /**	状态 1正在进行 2已结束 */
  status: string;
  /**	当前页码 */
  page: string;
  /**	每页条数 */
  limit: string;
  /**	搜索内容 */
  search?: string;
  /**	搜索类型 */
  searchType?: string;
  /**	排序字段 */
  order?: string;
  /**	是否升序 */
  asc?: string;
}

export const INVESTMENT_USER = '/server/market/investment/user/page';

export interface InvestmentUserReq {
  /**	状态 1正在进行 2已结束 */
  status: string;
  /**	当前页码 */
  page: string;
  /**	每页条数 */
  limit: string;
  /**	搜索内容 */
  search?: string;
  /**	搜索类型 */
  searchType?: string;
  /**	排序字段 */
  order?: string;
  /**	是否升序 */
  asc?: string;
}

export interface InvestmentUserResp {
  /*总记录数 */
  total: number;

  /*列表数据 */
  list: {
    /*拍剧项目配置ID */
    id: number;

    /*拍剧项目名 */
    name: string;

    /*拍剧金额 */
    buyMoney: string;

    /*累计发放金额 */
    totalIncome: string;

    /*购入时间 */
    buyTime: Record<string, unknown>;
  }[];

  /*扩展数据 */
  extend: Record<string, unknown>;
}

export const INVESTMENT_RECORDS = '/server/market/investment/records/page';

export interface InvestmentRecordsReq {
  /**	当前页码 */
  page: string;
  /**	每页条数 */
  limit: string;
  /**	搜索内容 */
  search?: string;
  /**	搜索类型 */
  searchType?: string;
  /**	排序字段 */
  order?: string;
  /**	是否升序 */
  asc?: string;
}

export interface InvestmentRecordsResp {
  /*总记录数 */
  total: number;

  /*列表数据 */
  list: {
    /*拍剧项目配置ID */
    id: number;

    /*拍剧项目名 */
    name: string;

    /*拍剧金额 */
    buyMoney: string;

    /*发放金额 */
    incomeMoney: string;

    /*分红利率 百分比 */
    income: string;

    /*昨日分红利率 百分比 */
    yesterdayIncome: string;

    /*发放时间 */
    getIncomeTime: Record<string, unknown>;
  }[];

  /*扩展数据 */
  extend: Record<string, unknown>;
}

export const CHILD_LIST = '/server/invite/child/getList';

export interface ChildListReq {
  /**	当前页码 */
  page: string;
  /**	每页条数 */
  limit: string;
  /**	搜索内容 */
  search?: string;
  /**	搜索类型 */
  searchType?: string;
  /**	排序字段 */
  order?: string;
  /**	是否升序 */
  asc?: string;
}

export interface ChildListResp {
  /*总记录数 */
  total: number;

  /*列表数据 */
  list: {
    /*好友id */
    userId: number;

    /*昵称 */
    nickname: string;

    /*用户名(邀请码) */
    username: string;

    /*头像 */
    avatar: string;

    /*层级(1直邀好友，2二级好友) */
    level: number;

    /*手机号 */
    mobile: string;

    /*邮箱 */
    email: string;

    /*充值情况 false无充值 true充值 */
    payStatus: boolean;

    /*邀请时间 */
    inviteTime: Record<string, unknown>;

    /*星卡类型 */
    type: number;

    /*星团等级 */
    teamLevel: number;

    /*星团业绩 */
    teamMoney: string;

    /*个人业绩 */
    personMoney: string;

    /*星团会员数 */
    talentCardNum: number;
  };
}

export const TANLENT_CONVERT = '/server/user/talent/convert/use';

export interface TalentConvertReq {
  code: string;
}

export const TANLENTCARD_CONVERT = '/admin/talentCard/convert/getByCode';

export interface TalentCardConvertReq {
  code: string;
}
export interface TalentCardConvertResp {
  /*兑换码id */
  id: number;

  /*兑换码 */
  code: string;

  /*达人卡类型 */
  talentCardType: number;

  /*兑换天数 */
  days: number;

  /*是否使用 0 未使用 1 已使用 */
  status: number;

  /*创建人 */
  creator: number;

  /*使用人id */
  usedUserId: number;

  /*生成时间 */
  createTime: Record<string, unknown>;

  /*使用时间 */
  usedTime: Record<string, unknown>;

  /*金额 */
  amount: number;
}

export const TALENTCARD_COUPON = '/admin/talentCard/coupon/getByCode';

export interface TalentCardCouponReq {
  code: string;
}

export interface TalentCardCouponResp {
  /* */
  id: number;

  /*优惠码 */
  code: string;

  /*达人卡类型 */
  talentCardType: number;

  /*优惠金额 */
  amount: number;

  /*是否使用 0 未使用 1 已使用 */
  status: number;

  /*创建人 */
  creator: number;

  /*使用人id */
  usedUserId: number;

  /*时间 */
  createTime: Record<string, unknown>;
}

export const CAPTCHA_ADAPTIVE = '/server/user/send/captcha/adaptive';

export interface captchaAdaptivereq {
  /*验证码类型
  WALLET_TRANSFER：余额转账
  WALLET_PAY：余额支付
  PAY_PASSWORD：支付密码 */
  type: string;
}

export const PAY_PLATFORM = '/server/market/pay/platform/list';

export interface PayPlatformReq {
  /*金额 */
  amount: string;
}
export interface PayPlatformResp {
  /*ID */
  id: number;

  /*支付类型<br>1：支付宝<br>2：微信<br>3：银行卡 */
  payType: number;

  /*额外参数，jsonString：[{"key":"name","name":"用户名","required":1,"exp":""}] */
  params: string;

  /*图标连接 */
  icon: string;

  /*名字 */
  name: string;

  /*平台单次交易最小金额 */
  minAmount: number;
}

export const USER_INFO = '/server/user/info';

export interface UserInfoReq {
  /*用户id */
  userId: number;
}
export interface UserInfoResp {
  /*用户ID */
  userId: number;

  /*昵称 */
  nickname: string;

  /*头像 */
  avatar: string;

  /*性别 */
  gender: string;

  /*VIP到期日 */
  vipExpiredTime: Record<string, unknown>;

  /*VIP到期时间戳 */
  vipExpiredTimeStamp: number;

  /*会员标题（类型） */
  vipTitle: string;

  /*剧点余额 */
  points: number;

  /*是否绑定社交平台 */
  bindSocial: boolean;

  /*社交平台类型 */
  socialType: string;

  /*邮箱 */
  email: string;

  /*手机 */
  mobile: string;

  /*IM用户ID */
  imUserId: number;

  /*是否开启自动解锁剧集 */
  autoUnlockEpisode: boolean;

  /*是否设置支付密码 */
  payPassword: boolean;

  /*是否需要提示设置支付密码 */
  payPasswordPrompt: boolean;
}

export const Refresh = '/server/auth/token';

export interface RefreshReq {
  /*刷新token */
  refreshToken: string;
}

export interface RefreshResp {
  /*access_token 过期时间 */
  accessTokenExpire: Record<string, unknown>;

  /*access_token */
  access_token: string;
}

export const TRANSACTION_COMMODITY = '/server/transaction/commodity/page-v2';

export interface TransactionCommodityReq {
  /*当前页码 */
  page: string;
  /*每页条数 */
  limit: string;
  /**	用户ID */
  userId?: string;
  /**	商品状态 1：发布 2：锁定 3：结束 4：下架 */
  status?: string;
  /**	商品类型 1：买 2：卖 */
  type?: string;
  /**	是否包含订单信息 */
  includeOrderInfo?: string;
  /**	是否包含删除信息 */
  includeDeleted?: string;
  /**	支付方式 */
  payWay?: string;
  /*搜索内容 */
  search?: string;
  /*搜索类型 */
  searchType?: string;
  /*排序字段 */
  order?: string;
  /*是否升序 */
  asc?: string;
}

export interface TransactionCommodityResp {
  /*上次播放剧集ID */
  lastEpisodeId: number;

  /*上次播放集数 */
  lastPlayIndex: number;

  /*地址 */
  url: string;

  /*视频ID */
  videoId: string;

  /*视频播放凭证 */
  videoToken: string;

  /*视频源地址 */
  videoSourceUrl: string;

  /*上次播放时间 */
  lastPlayTime: number;

  /*剧集列表 */
  episodes: {
    /*ID */
    id: number;

    /*集数 */
    index: number;

    /*封面图 */
    coverPath: string;

    /*是否锁定 */
    lock: boolean;

    /*播放模式，0：免费，1：广告，2：付费（付费/VIP限次数观看） */
    playMode: number;

    /*解锁所需剧点 */
    points: number;
  };
}

export const TRANSACTION_PUBLISH = '/server/transaction/publish';

export interface TransactionPublishReq {
  amount: number;
  integral: number;
  type: number;
  payWay: number;
  payDesc: string;
  creator: number;
}

export const TRANSACTION_RECORD = '/admin/transaction/get/pay/way';

export interface TransactionRecordResp {
  /*id */
  id: number;

  /*name */
  name: string;

  /*是否开启 */
  enable: number;
}

export const TRANSACTION_ORDER = '/server/transaction/order/page';

export interface TransactionOrderReq {
  /*当前页码 */
  page: string;
  /*每页条数 */
  limit: string;
  /**	用户ID */
  userId?: string;
  /*	1 正常订单 0 异常订单 */
  normal?: string;
  /**	商品状态 0：创建 1：即将超时 2：超时 3：取消 4：买家确认 5：卖家确认 6：交易成功 */
  status?: string;
  /**	商品类型 1：买 2：卖 */
  type?: string;
  /**	支付方式 */
  payWay?: string;
  /*搜索内容 */
  search?: string;
  /*搜索类型 */
  searchType?: string;
  /*排序字段 */
  order?: string;
  /*是否升序 */
  asc?: string;
}

export const TRANSACTION_TIME = '/server/transaction/time';

export const TRANSACTION_NUMBER = '/server/transaction/order/user/number';

export interface TransactionNumberReq {
  userIds: string;
}

export const NOTIFY_STATUS = '/server/pay/notify/status';

export interface NotifyStatusReq {
  /*订单号 */
  orderNo: string;
}

export const MARKET_TASK = '/server/market/task/info';

export interface MarketTaskResp {
  /*任务ID */
  taskId: number;

  /*任务记录Id */
  taskReleaseId: number;

  /*任务名称 */
  taskName: string;

  /*任务时间 */
  taskTime: Record<string, unknown>;

  /*任务内容 */
  taskContent: Record<string, unknown>[];

  /*任务图片 */
  taskImageList: Record<string, unknown>[];

  /*文案素材 */
  sourceContent: Record<string, unknown>[];

  /*图片素材 */
  sourceImageList: Record<string, unknown>[];

  /*任务状态 1.审核未通过（请重新提交）2.已领取（提交任务） 3.已提交（等待审核）4.未领取（领取任务) 5.已完成（完成） */
  taskStatus: number;

  /*审核未通过原因 */
  reason: string;
}

export const MARKET_TASK_TAKE = '/server/market/task/take';

export interface MarketTaskTakeReq {
  /*任务ID */
  taskId: number;
}

export const FILE_STS = 'server/auth/file/sts';

export const ADMIT_TASK = '/server/market/task/submit';

export interface AdmitTaskReq {
  // 任务ID
  id: string;
  // 	图片地址列表
  imageUrls: string;
  //图片内容，请与图片一一对应
  contents?: string;
}

export const USER_CODE_LIST = '/server/market/user/code/list';

export interface UserCodeListReq {
  /*当前页码 */
  page: string;
  /*每页条数 */
  limit: string;
  /*搜索内容 */
  search?: string;
  /*搜索类型 */
  searchType?: string;
  /*排序字段 */
  order?: string;
  /*是否升序 */
  asc?: string;
}

export interface UserCodeListResp {
  /*总记录数 */
  total: number;

  /*列表数据 */
  list: {
    /*兑换码 */
    code: string;

    /*时长，单位：天 */
    duration: number;

    /*有效期 */
    expireDate: Record<string, unknown>;

    /*状态<br>0：未使用、1：已使用 */
    status: number;
  }[];

  /*扩展数据 */
  extend: Record<string, unknown>;
}

export const UPDATE_PASSWORD = '/server/user/update/password';

export interface UpdatePasswordReq {
  /** 旧密码 */
  oldPassword: string;

  /** 新密码 */
  newPassword: string;
  /** 	验证码，此值不为空时为充值 */
  captcha: string;
}

export const Login_Market = '/server/auth/login/market';

export interface LoginMarketReq {
  //登录码
  code: string;
}

export interface LoginMarketResp {
  /* */
  userInfo: {
    /*用户ID */
    userId: number;

    /*昵称 */
    nickname: string;

    /*头像 */
    avatar: string;

    /*性别 */
    gender: string;

    /*VIP到期日 */
    vipExpiredTime: Record<string, unknown>;

    /*VIP到期时间戳 */
    vipExpiredTimeStamp: number;

    /*会员标题（类型） */
    vipTitle: string;

    /*剧点余额 */
    points: number;

    /*是否绑定社交平台 */
    bindSocial: boolean;

    /*社交平台类型 */
    socialType: string;

    /*邮箱 */
    email: string;

    /*手机 */
    mobile: string;

    /*IM用户ID */
    imUserId: number;

    /*是否开启自动解锁剧集 */
    autoUnlockEpisode: boolean;

    /*是否设置支付密码 */
    payPassword: boolean;

    /*是否需要提示设置支付密码 */
    payPasswordPrompt: boolean;

    /*是否设置登录密码 */
    loginPassword: boolean;
  };

  /* */
  tokenInfo: {
    /*access_token 过期时间 */
    accessTokenExpire: Record<string, unknown>;

    /*refresh_token 过期时间 */
    refreshTokenExpire: Record<string, unknown>;

    /*access_token */
    access_token: string;

    /*refresh_token */
    refresh_token: string;
  };

  /*RSA公钥，请使用Base64解码 */
  rpuk: string;

  /*是否是新用户：0->新用户 1-老用户 */
  newOrOld: number;
}

export const TransactionServe_goods = '/server/transaction/commodity/getById';

export interface TransactionServeGoodsReq {
  id: string | number;
  includeOrder?: boolean;
}

export interface TransactionServeGoodsResp {
  id: number;
  amount: number;
  integral: number;
  status: number;
  type: number;
  orderId: number;
  creator: number;
  createTime: string;
  updater: number;
  updateTime: string;
  deleted: number;
  version: number;
  payWay: number;
  payDesc: string;
  order?: any;
}

export const TransactionServe_order = '/server/transaction/buy';

export interface TransactionServeOrderReq {
  commodityId: number;
  payWay?: number;
  payDesc: string;
}

export const FetchRemove_order = '/server/transaction/cancel';

export interface RemoveOrderReq {
  orderId: string;
  version: string;
}

export const Fetch_Removed_goods = '/server/transaction/revocation';

export interface RemovedGoodsReq {
  commodityId: number;
  version: string;
}

export const Get_payway = '/admin/transaction/get/pay/way';

export interface PayWayResp {
  id: number;
  name: string;
  enable: number;
}

export const Transaction_servePay = '/server/transaction/pay';

export interface TransactionServePayReq {
  orderId: number;
  version: number;
}

export const Transaction_confirm = '/server/transaction/confirm';

export interface TransactionConfirmReq {
  orderId: number;
  version: number;
}

export const Transaction_imGroup = '/server/transaction/imGroup';

export interface TransactionImGroupReq {
  orderId: number;
}

export const Transaction_order_excepion = '/server/transaction/order/excepion';

export interface TransactionOrderExcepionReq {
  orderId: number;
  version: number;
}

export const Transaction_order_info = '/server/transaction/order/info';

export interface TransactionOrderInfoReq {
  id: number;
}

export interface TransactionOrderInfoResp {
  id: number;
  code: string;
  commodityId: number;
  status: number;
  normal: number;
  buyUser: number;
  sellUser: number;
  kfUserId: number;
  imGroupId: number;
  creator: number;
  createTime: string;
  updater: number;
  updateTime: string;
  version: number;
  payWay: number;
  payDesc: string;
  commodityVO?: any;
}

export const Transaction_discuss_talk = '/server/transaction/discuss/talk';

export interface TransactionDiscussTalkReq {
  orderId: number;
  userId: number;
  content: string;
}

export const Transaction_discuss_list = '/server/transaction/discuss/list';

export interface TransactionDiscussListReq {
  orderId: number;
}

export interface TransactionDiscussListResp {
  orderId: number;
  sellUser: number;
  createTime: string;
  content: string;
}

export const Transaction_order_page = '/server/transaction/order/page';

export interface TransactionOrderPageReq {
  page: string;
  limit: string;
  type?: string;
  status?: string;
  userId?: number;
  normal?: string;
  payWay?: string;
  search?: string;
  searchType?: string;
  order?: string;
  asc?: string;
}

export const Transaction_commodity_my = '/server/transaction/commodity/my/page';

export interface TransactionCommodityPageReq {
  page: string;
  limit: string;
  type?: string;
  status?: string;
  userIds?: number;
  includeOrderInfo?: string;
  includeDeleted?: string;
  payWay?: string;
  search?: string;
  searchType?: string;
  order?: string;
  asc?: string;
}

export const Transaction_serve_remnant_count =
  '/server/transaction/remnantCount';

export interface TransactionRemnantCountReq {
  userId: number;
}

export const Transaction_commodity_update =
  '/server/transaction/commodity/update';

export interface TransactionCommodityUpdateReq {
  id: number;
  payWay: number;
  payDesc: string;
  creator?: number;
  version: number;
}

export const Transaction_serve_config = '/sys/params/getByName';

export interface TransactionServeConfigReq {
  name: string;
}

export const LANGUAGE_LIST = '/admin/sys/language/list';
export interface LanguageListReq {
  status: 0 | 1;
}
export interface LanguageListResp {
  id: number;
  name: string;
  abbreviation: string;
  status: string;
  icon: string;
}
